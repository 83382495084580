import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["slide"]

  initialize() {
    this.showCurrentSlide()
  }

  next() {
    this.index++
    if (this.index > this.data.get("count")) this.index = this.data.get("count")
  }

  previous() {
    this.index--
    if (this.index == -1) this.index = 0
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index == i)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentSlide()
  }
}